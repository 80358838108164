// Happy Guest
@import '../../fonts/fonts.scss';


// Fonts
$worksans-regular : Work Sans;
$worksans-black : WorkSans-Black;
$worksans-bold : WorkSans-Bold;
$worksans-semibold : WorkSans-SemiBold;
$worksans-medium : WorkSans-Medium;
$worksans-extrabold : WorkSans-ExtraBold;
$worksans-light : WorkSans-Light;
$worksans-extralight : WorkSans-ExtraLight;
$worksans-thin : WorkSans-thin;

// Size
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-17-92: 17.92px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-34-4: 34.4px;

// Color
$text-white: white;
$text-black: black;
$text-overline: #0071B0;
$text-label: #4e4e4e;
$text-label-checkbox: #000000;
$text-datalabel: #000000;
$text-para: rgba(0, 0, 0, 0.5);
$text-para-2: rgba(0, 0, 0, 0.7);
$text-para-3: rgba(0, 0, 0, 0.8);
$text-para-4: rgba(0, 0, 0, 0.64);

// background

$black: black;
$white: white;
$innerbg: #25211E;

// divider
// $dividerbg: rgba(0, 0, 0, 0.3);
$dividerbg: rgba(0, 0, 0, 0);

// card bg

$yellow: #FFED49;
$orange: #FFC062;
$blue: #7BE8FF;
$green: #A5FF66;
$pink: #FF8DE8;