/* ###### Happy Guest Fonts ###### */

@mixin worksansregular {
    font-family: $worksans-regular;
    src: url(../fonts/work-sans/WOFF/WorkSans-Regular.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Regular.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Regular.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Regular.otf);
}
@mixin worksansblack {
    font-family: $worksans-black;
    src: url(../fonts/work-sans/WOFF/WorkSans-Black.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Black.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Black.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Black.otf);
}
@mixin worksanssemibold {
    font-family: $worksans-semibold;
    src: url(../fonts/work-sans/WOFF/WorkSans-SemiBold.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-SemiBold.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-SemiBold.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-SemiBold.otf);
}
@mixin worksansmedium {
    font-family: $worksans-medium;
    src: url(../fonts/work-sans/WOFF/WorkSans-Medium.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Medium.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Medium.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Medium.otf);
}
@mixin worksansbold {
    font-family: $worksans-bold;
    src: url(../fonts/work-sans/WOFF/WorkSans-Bold.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Bold.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Bold.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Bold.otf);
}
@mixin worksansextrabold {
    font-family: $worksans-extrabold;
    src: url(../fonts/work-sans/WOFF/WorkSans-ExtraBold.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-ExtraBold.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-ExtraBold.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-ExtraBold.otf);
}
@mixin worksanslight {
    font-family: $worksans-light;
    src: url(../fonts/work-sans/WOFF/WorkSans-Light.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Light.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Light.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Light.otf);
}
@mixin worksansextralight {
    font-family: $worksans-extralight;
    src: url(../fonts/work-sans/WOFF/WorkSans-ExtraLight.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-ExtraLight.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-ExtraLight.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-ExtraLight.otf);
}
@mixin worksanthin {
    font-family: $worksans-thin;
    src: url(../fonts/work-sans/WOFF/WorkSans-Thin.woff);
    src: url(../fonts/work-sans/WOFF2/WorkSans-Thin.woff2);
    src: url(../fonts/work-sans/TTF/WorkSans-Thin.ttf);
    src: url(../fonts/work-sans/OTF/WorkSans-Thin.otf);
}