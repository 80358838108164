@import './variables';
@import '../../fonts/fonts.scss';

// Fonts

@font-face {  @include worksansregular;}
@font-face {  @include worksansblack;}
@font-face {  @include worksansbold;}
@font-face {  @include worksanssemibold;}
@font-face {  @include worksansmedium;}
@font-face {  @include worksansextrabold;}
@font-face {  @include worksanslight;}
@font-face {  @include worksansextralight;}
@font-face {  @include worksanthin;}

// Typhography

@mixin heading1 {
    font-size: $font-size-34-4;
    font-family: $worksans-regular;
    font-weight: 600;
    color:$text-white;
    // letter-spacing: -0.2px;
    // line-height: 40.35px;
}

@mixin heading2 {
    font-size: $font-size-20;
    font-family: $worksans-regular;
    // font-family: $worksans-medium;
    font-weight: 600;
    color: $text-black;
    margin-bottom: 0;
    // letter-spacing: -0.7px;
}

@mixin heading3 {
    font-size: $font-size-18;
    // font-family: $worksans-medium;
    // font-family: $worksans-regular;
    font-family: $worksans-semibold;
    font-weight: 700;
    color: $text-white;
    padding: 20px 0;
    text-align: center;
    margin-bottom: 0;
    // letter-spacing: -0.7px;
}

@mixin heading4 {
    font-size: $font-size-17-92;
    font-family: $worksans-regular;
    font-weight: 400;
    color: $text-white;
    margin: 12px 0 10px;
    // letter-spacing: -0.2px;
}

@mixin heading5 {
    font-size: $font-size-16;
    // font-family: $worksans-regular;
    font-family: $worksans-medium;
    font-weight: 600;
    color: $text-black;
    margin-bottom: 5px;
    // letter-spacing: -0.2px;
}

@mixin  heading6{
    font-size: $font-size-13;
    // font-family: $worksans-light;
    font-family: $worksans-regular;
    font-weight: 300;
    color: $text-white;
    // letter-spacing: -0.2px;
}

@mixin paragraph {
    font-size: $font-size-14;
    font-family: $worksans-regular;
    font-weight: 400;
    // color: $text-para-2;
    line-height: 16.98px;
    // letter-spacing: -0.7px;
    color: $text-black;
    opacity: 70%;
   
}

@mixin paragraph2 {
    font-size: $font-size-13;
    font-family: $worksans-regular;
    font-weight: 400;
    color: $text-black;
    opacity: 0.7;
    line-height: 16.98px;
    // letter-spacing: -0.7px;
}

@mixin text-overline {
    font-size: $font-size-13;
    font-family: $worksans-semibold;
    // font-family: $worksans-regular;
    font-weight: 700;
    color: $text-white;
    text-decoration: underline;
    letter-spacing: -0.2px;
}

@mixin text-overline2 {
    font-size: $font-size-14;
    font-family: $worksans-regular;
    font-weight: 400;
    color: $text-overline;
    text-decoration: underline;
    letter-spacing: -0.2px;
}

// Form

// label

@mixin label {
    font-size: $font-size-14;
    font-family: $worksans-regular;
    font-weight: 400;
    // color: $text-label;
    // color: $text-para-2;
    color: $text-black;
    opacity: 70%;
    margin-bottom: 2px;
}
@mixin label2 {
    font-size: $font-size-14;
    font-family: $worksans-regular;
    font-weight: 400;
    color: $text-black;
    // color: $text-para-4;
    margin-bottom: 2px;
    opacity: 64%;
}
@mixin dataLabel {
    font-size: $font-size-14;
    font-family: $worksans-regular;
    font-weight: 600;
    // color: $text-datalabel;
    color: $text-para-3;
}

@mixin dataLabel2 {
    font-size: $font-size-16;
    font-family: $worksans-regular;
    font-weight: 600;
    color: $text-black;
}

@mixin dataLabel3 {
    font-size: $font-size-16;
    font-family: $worksans-medium;
    font-weight: 600;
    color: $text-black;
}

// input

@mixin input-text {
    font-size: $font-size-16;
    font-family: $worksans-regular;
    font-weight: 400;
    border-radius: 12px;
    height: 49px;
    border: 2px solid rgba(255,255,255,0.1);
    background: rgba(255,255,255,0.1) !important;
    color: $text-white !important;
    text-transform: uppercase;
    // padding: 15px 12px 9px;
    &::placeholder {
        opacity: 3;
        color: rgba(255,255,255,0.7) !important;
        text-transform: initial;
    }

    &:focus, &:active {
        border: 2px solid rgba(255,255,255,0.1);
        box-shadow: none;
        color: $text-white !important;
        background: rgba(255,255,255,0.1) !important;
    }

}

@mixin input-text2 {
    font-size: $font-size-16;
    font-family: $worksans-regular;
    font-weight: 400;
    border-radius: 12px;
    height: 49px;
    border: 2px solid rgba(0, 0, 0, 0.08);
    background: rgba(0, 0, 0, 0.04) !important;
    color: $text-black;
    padding-top: 6px;
    &::placeholder {
        // opacity: 3;
        // color: rgba(0, 0, 0, 0.4) !important;
        opacity: 60%;
        color: $text-black;
    }
    &:focus, &:active {
        border: 2px solid #181818 !important;
        box-shadow: none;
    }
}

@mixin input-text-otp {
    font-size: $font-size-16;
    font-family: $worksans-regular;
    font-weight: 400;
    border-radius: 12px;
    height: 49px;
    width: 49px;
    border: 2px solid rgba(0, 0, 0, 0.08);
    background: rgba(0, 0, 0, 0.04) !important;
    color: $text-black;
    padding-top: 6px;
    text-align: center;
    &::placeholder {
        opacity: 3;
        color: rgba(0, 0, 0, 0.4) !important;
    }
    &:focus, &:active {
        border: 2px solid #181818 !important;
        box-shadow: none;
    }
}

// accordion

@mixin accordiontitle {
    font-size: $font-size-20;
    font-family: $worksans-black;
    font-weight: 600;
}

// silder
@mixin sliderpagination {
    font-size: $font-size-12;
    font-family: $worksans-black;
    font-weight: 500;
    opacity: 50%;
}

// Buttons variants

// outline

@mixin outlinebutton {
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-transform: lowercase;
    font-size: $font-size-16;
    // font-family: $worksans-medium;
    font-family: $worksans-semibold;
    // letter-spacing: -0.7px;
    font-weight: 700;
    text-align: center;
    width: 100px;
    height: 56px;
    border-radius: 50px;
    background: none;
    color: $text-black;
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }

    &:focus, &:hover, &:active {
        border: 2px solid $black !important;
        background: none !important;
    }
}

// fill

@mixin fillbutton {
    border: 2px solid $black;
    background-color: $black;
    text-transform: capitalize;
    font-size: $font-size-16;
    // font-family: $worksans-medium;
    font-family: $worksans-semibold;
    // letter-spacing: -0.7px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    height: 56px;
    border-radius: 50px;
    // box-shadow: 0 10px 16px rgba(66, 63, 37, 0.4);
    color: $text-white;
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }
    &:focus, &:hover, &:active {
        border: 2px solid $black !important;
        background-color: $black !important;
    }
}


@mixin fillbuttonwhite {
    border: 2px solid $white;
    background-color: $white;
    color: $text-black;
    text-transform: capitalize;
    font-size: $font-size-16;
    font-family: $worksans-semibold;
    // font-family: $worksans-regular;
    font-weight: 700;
    text-align: center;
    width: 100%;
    height: 56px;
    border-radius: 50px;
    box-shadow: 0 10px 16px rgba(66, 63, 37, 0.4);
    letter-spacing: -0.7px;
    &:focus,  &:active, &:hover {
    border: 2px solid $black !important;
    background-color: $black !important;
    color: $text-white;
   }
}

// Modalbox

@mixin modalbox {

    .modal-bottom {
        animation: slideUp 0.5s ease forwards;
      }
      
      @keyframes slideUp {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }

      .modal-dialog {
        // margin: 0;
        // bottom: inherit;
        // position: absolute;
        // top: 25%;
        top: 35%;
        // width: 17%;
        // max-width: 17%;
        width: 21%;
        max-width: 21%;
        // height: auto;
        // left: 40%;
        // transform: translate(-50%, -50%);
        
        @media(max-width: 1600px) {
            // top: 25%;
            top: 35%;
            width: 25%;
            max-width: 25%;
        }
        
        @media(max-width: 1280px) {
            // top: 25%;
            top: 35%;
            width: 30%;
            max-width: 30%;
        }


        @media(max-width: 1024px) {
            // top: 25%;
            top: 35%;
            width: 47%;
            max-width: 47%;
        }

        @media(max-width: 991px) {
            // top: 25%;
            top: 35%;
            width: 47%;
            max-width: 47%;

        }

        @media(max-width: 767px) {
            margin: 0;
            bottom: -1px;
            position: fixed;
            width: 100% !important;
            max-width: 100% !important;
            height: auto;
            transform: inherit !important;
            left: inherit !important;
            top: initial !important;
         }

        .modal-content {
            border-radius: 18px;

            
            @media(max-width: 991px) { 
                border-radius: 18px;
            }
            @media(max-width: 767px) { 
                border-radius: 18px 18px 0 0;
            }
            .modal-header {
                padding: 10px 15px 0;
                border: none;
                display: block;
                width: 100%;
                text-align: right;
                position: absolute;
                z-index: 1;

                @media(max-width: 767px) { 
                    padding: 5px 15px 0;
                    text-align: center;
                    position: relative;
               }
    
                button {

                    // @media(max-width: 767px) { 
                    //      background-image: url(../../images/close-btn.svg);
                    // }

                    &:focus, &:active {
                        box-shadow: none;
                        border: none;
                    }
                }
            }
            .modal-body {
                padding-top: 5px;

                h2 {
                        @include heading2;
                        font-family: $worksans-medium;
                        margin-bottom: 15px;
                        padding-right: 30px;
                        padding-top: 15px;
                        // letter-spacing: normal;
                        @media(max-width: 767px) { 
                            padding-right: 15px;
                            padding-top: 0px  !important;
                         }
                }

                h6 {
                    @include heading6;
                    color: $text-black;
                    opacity: 60%;
                    font-weight: 400;
                }

                input {
                    @include input-text2 ;
                }
                button.outlinebutton {
                    @include outlinebutton;
                }
                button.fillbutton {
                    @include fillbutton;
                    width: 68%;

                   &:disabled {
                        border: 2px solid $black !important;
                        background: $black !important;
                        opacity: 10% !important;
                     }
                }
            }
          }
      }
}